import React, { Component } from "react"
import { Row, Col, Input } from "antd"

import { Gallery } from "../components/Gallery"

import { CloseOutlined, EditOutlined } from "@ant-design/icons"

export class InteractiveRoom extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _image_count: 0,
      _images: props.images,
      _name: props.name,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.fileInput = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      _images: nextProps.images,
      _name: nextProps.name,
    })
  }

  handleInputChange(event) {
    const new_name = event.target.value
    this.props.update_room_name(this.props.index, new_name)
  }

  render() {
    const dragOver = (e) => {
      e.preventDefault()
    }

    const dragEnter = (e) => {
      e.preventDefault()
    }

    const dragLeave = (e) => {
      e.preventDefault()
    }

    function previewFile(file, gallery) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = function () {
        let img = document.createElement("img")
        img.src = reader.result
        gallery.appendChild(img)
      }
    }

    const fileDrop = (e) => {
      e.preventDefault()
      const files = e.dataTransfer.files

      for (const file of files) {
        if (file.type === "image/jpeg") {
          this.props.add_photo(this.props.index, file)
        } else {
          alert("Seul le format JPG est accepté")
        }
      }
    }

    const handleChange = () => {
      const selectedFiles = this.fileInput.current.files
      for (const file of selectedFiles) {
        this.props.add_photo(this.props.index, file)
      }
    }

    return (
      <Row justify="center">
        <Col sm={22} md={20} lg={16} xl={16}>
          <div
            style={{
              height: "15px",
              width: "15px",
              position: "relative",
              top: "10px",
              left: "-12px",
              backgroundColor: "white",
              display: "inline",
              borderRadius: "20px",
              padding: "5px",
              paddingLeft: "7px",
              paddingRight: "7px",
            }}
            onClick={() => this.props.remove_room()}
          >
            <CloseOutlined />
          </div>
          <div className="interactive-room-container">
            <div className="interactive-room-header">
              <Input
                value={this.state._name}
                prefix={<EditOutlined />}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="interactive-room-body">
              <div
                className="drop-area"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              >
                <p className="drop-area-label">
                  Faites glissez des photos (JPG) pour la prédiction.
                </p>

                <label>
                  <p className="file-input-label">Ajouter des photos</p>
                  <input
                    type="file"
                    multiple
                    accept="image/jpeg"
                    ref={this.fileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <Gallery images={this.props.images} />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

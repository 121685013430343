import React, { Component } from "react";

export class Gallery extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const gallery = this.DOMgallery;

    this.props.images.forEach((image) => {
      this.previewFile(image, gallery);
    });
  }

  componentWillReceiveProps(nextProps) {
    const gallery = this.DOMgallery;

    const next_length = nextProps.images.length;
    const old_length = gallery.childElementCount;

    nextProps.images.slice(old_length, next_length).forEach((image) => {
      this.previewFile(image, gallery);
    });
  }

  previewFile(file, gallery) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      let img = document.createElement("img");
      img.src = reader.result;
      gallery.appendChild(img);
    };
  }

  render() {
    return <div className="gallery" ref={(ref) => (this.DOMgallery = ref)} />;
  }
}

import React, { Component } from "react"
import { Row, Col, Button, Select } from "antd"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { Gallery } from "./Gallery"

export default class ResultRoom extends Component {
  constructor(props) {
    super(props)

    const label_counter = {}

    this.state = {
      _label_counter: label_counter,
      _select: "chaise"
    }
  }

  render() {
    return (
      <Row>
        <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} />
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }}>
          <div className="interactive-room-container">
            <div className="interactive-room-header">{this.props.name}</div>
            <div className="interactive-room-body">
              <Gallery images={this.props.photos} />
            </div>
          </div>
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 2 }} />
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 11 }}>
          <div className="interactive-room-container">
            <div className="interactive-room-header">
              {`Résultat - ${this.props.name}`}
              <br />
            </div>
            <div className="interactive-room-body">
              {Object.keys(this.props.objects).map((label) => {
                return (
                  <Row className="result-row">
                    <Col span={10}>
                      <p className="result-label"> {label} :</p>
                    </Col>
                    <Col span={1} />

                    <Col span={4}>
                      <Button
                        type="primary"
                        size="default"
                        onClick={() => this.props.substractObject(label)}
                        icon={<MinusOutlined />}
                      />
                    </Col>

                    <Col span={4} className="result-counter-column">
                      {this.props.objects[label]}
                    </Col>

                    <Col span={4}>
                      <Button
                        type="primary"
                        size="default"
                        onClick={() => this.props.addObject(label)}
                        icon={<PlusOutlined />}
                      />
                    </Col>
                  </Row>
                )
              }, this)}
              <Row className="result-row" justify="center">
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                  <Select
                    className="result-label-select"
                    value={this.state._select}
                    options={this.props.datas.options}
                    searchable="false"
                    style={{ width: 120 }}
                    onChange={(e) => this.setState({ _select: e })}
                    defaultValue={this.state._default_value}
                  />
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 6 }}>
                  <Button
                    type="primary"
                    size="default"
                    onClick={() => this.props.addObject(this.state._select)}
                  >
                    Ajouter
                  </Button>
                </Col>
              </Row>
              <div className="result-room-footer">
                Volume de la pièce: {this.props.volume.toFixed(1)} m³
              </div>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 0 }} lg={{ span: 0 }} />
      </Row>
    )
  }
}

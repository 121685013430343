import React, { Component } from "react"
import { Helmet } from "react-helmet"
import {
  Row,
  Col,
  Button,
  InputNumber,
  Select,
  Input,
  Divider,
  Switch,
  Form,
} from "antd"
import { navigate } from "@reach/router"
import data from "../assets/labelmap.json"

import { InteractiveRoom } from "../components/InteractiveRoom"

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _is_pro: false,
      _prediction: {
        total_volume: 23.4,
        room_estimations: [
          {
            volume: 0.0,
            objects_detected: {
              objects: [],
            },
          },
        ],
      },
      _rooms: [
        {
          name: "Pièce 1",
          photos: [],
        },
      ],
      _volumes: {},
    }
  }

  componentDidMount() {
    const options = []
    const volumes = {}
    Object.entries(data).map(([key, value]) => {
      options.push({
        value: value.name,
        label: value.name,
      })
      volumes[value.name] = value.volume
    })
    const datas = { volumes, options }
    this.setState({
      _datas: datas,
    })
  }

  update_room_name(room_index, new_name) {
    const temp_rooms = this.state._rooms
    temp_rooms[room_index].name = new_name
    this.setState({ _rooms: temp_rooms })
  }

  add_photo(room_index, file) {
    const temp_rooms = this.state._rooms
    temp_rooms[room_index].photos.push(file)
    this.setState({ _rooms: temp_rooms })
  }

  add_room() {
    const nb_rooms = this.state._rooms.length
    const temp_rooms = this.state._rooms
    temp_rooms.push({
      name: `Pièce ${String(nb_rooms + 1)}`,
      photos: [],
    })
    this.setState({ _rooms: temp_rooms })
  }

  remove_room(index) {
    this.setState({
      _rooms: this.state._rooms.filter((room, idx) => idx !== index),
    })
  }

  async start_prediction(values) {
    this.setState({ _isloading: true })

    const housing_info = {
      BETA_first_name: values._first_name,
      BETA_last_name: values._last_name,
      BETA_society: values._society,
      BETA_mail: values._mail,
      BETA_tel: values._tel,
      BETA_surface: values._surface,
      BETA_nb_habitants: values._nb_habitants,
      BETA_estimated_volume: values._estimated_volume,
    }

    const prediction = await uploadFiles(this.state._rooms, housing_info)

    if (prediction.errors) {
      return alert("Une erreur est survenue")
    }

    let total_volume = 0.0
    this.state._rooms.forEach((room, index) => {
      prediction.room_estimations[index].objects_detected.objects.forEach(
        (object) => {
          if (this.state._datas.volumes[object.label] === undefined) {
            total_volume += 0.2
          } else {
            total_volume += this.state._datas.volumes[object.label]
          }
        }
      )
    })
    prediction.total_volume = total_volume

    this.setState({
      _prediction: prediction,
      _isloading: false,
    })

    navigate("/results", {
      state: { _prediction: prediction, _rooms: this.state._rooms },
    })
  }

  calculateVolume(index, volume) {
    this.state._volumes[index] = volume
    let volume_total = 0
    Object.keys(this.state._volumes).map(
      (key) => (volume_total += this.state._volumes[key])
    )
    const prediction = this.state._prediction
    prediction.total_volume = volume_total
    this.setState({ _prediction: prediction })
  }

  static get_datas() {}

  enterLoading() {
    this.setState({ _isloading: true })
  }

  change_preset(e) {
    // TODO : Changer tout ça

    if (e === "studio") {
      this.setState({
        _rooms: [
          {
            name: "Pièce à vivre",
            photos: [],
          },
          {
            name: "Salle d'eau",
            photos: [],
          },
        ],
      })
    } else if (e === "t2") {
      this.setState({
        _rooms: [
          {
            name: "Pièce à vivre",
            photos: [],
          },
          {
            name: "Cuisine",
            photos: [],
          },
          {
            name: "Salle d'eau",
            photos: [],
          },
          {
            name: "Chambre",
            photos: [],
          },
        ],
      })
    } else if (e === "t3") {
      this.setState({
        _rooms: [
          {
            name: "Pièce à vivre",
            photos: [],
          },
          {
            name: "Cuisine",
            photos: [],
          },
          {
            name: "Salle d'eau",
            photos: [],
          },
          {
            name: "Chambre 1",
            photos: [],
          },
          {
            name: "Chambre 2",
            photos: [],
          },
        ],
      })
    } else if (e === "t4") {
      this.setState({
        _rooms: [
          {
            name: "Pièce à vivre",
            photos: [],
          },
          {
            name: "Cuisine",
            photos: [],
          },
          {
            name: "Salle d'eau",
            photos: [],
          },
          {
            name: "Chambre 1",
            photos: [],
          },
          {
            name: "Chambre 2",
            photos: [],
          },
          {
            name: "Chambre 3",
            photos: [],
          },
        ],
      })
    } else if (e === "t5+") {
      this.setState({
        _rooms: [
          {
            name: "Pièce à vivre",
            photos: [],
          },
          {
            name: "Cuisine",
            photos: [],
          },
          {
            name: "Salle d'eau",
            photos: [],
          },
          {
            name: "Chambre 1",
            photos: [],
          },
          {
            name: "Chambre 2",
            photos: [],
          },
          {
            name: "Chambre 3",
            photos: [],
          },
          {
            name: "Chambre 4",
            photos: [],
          },
        ],
      })
    }
  }

  render() {
    const layout = {
      labelCol: {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 18 },
      },
    }

    return (
      <div>
        <Helmet>
          <title>IA Volume</title>
        </Helmet>
        <div>
          <div className="interactive-room-container">
            <div className="interactive-room-header">
              Informations sur le logement
            </div>
            <div className="interactive-room-body">
              <Row>
                <Col span={24}>
                  <Form.Item label={"Professionnel\xa0\xa0"}>
                    <Switch
                      onChange={(statut) => this.setState({ _is_pro: statut })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form
                name="Form"
                {...layout}
                onFinish={(values) => this.start_prediction(values)}
                id="form-prediction"
              >
                {this.state._is_pro && (
                  <React.Fragment>
                    <Row>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="Nom" name="_last_name">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="Prenom" name="_first_name">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="Société" name="_society">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="Email" name="_mail">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item label="Telephone" name="_tel">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider />
                  </React.Fragment>
                )}

                <Row>
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: this.state._is_pro ? 6 : 8 }}
                  >
                    <Form.Item
                      label="Surface du logement"
                      name="_surface"
                      labelCol={{
                        xs: { span: 12 },
                        md: { span: 12 },
                      }}
                      wrapperCol={{
                        xs: { span: 12 },
                        md: { span: 12 },
                      }}
                    >
                      <InputNumber
                        min={0}
                        formatter={(value) => `${value}m²`}
                        parser={(value) => value.replace("m²", "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: this.state._is_pro ? 6 : 8 }}
                  >
                    <Form.Item
                      label="Nombre d'habitants"
                      name="_nb_habitants"
                      labelCol={{
                        xs: { span: 12 },
                        md: { span: 12 },
                      }}
                      wrapperCol={{
                        xs: { span: 12 },
                        md: { span: 12 },
                      }}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: this.state._is_pro ? 6 : 8 }}
                  >
                    <Form.Item
                      label="Type de logement"
                      name="select_size"
                      labelCol={{
                        xs: { span: 12 },
                        md: { span: 11 },
                      }}
                      wrapperCol={{
                        xs: { span: 12 },
                        md: { span: 12 },
                      }}
                    >
                      <Select
                        defaultValue="Aucun"
                        style={{ width: 120 }}
                        onChange={(e) => this.change_preset(e)}
                      >
                        <Select.Option value="studio">Studio</Select.Option>
                        <Select.Option value="t2">T2</Select.Option>
                        <Select.Option value="t3">T3</Select.Option>
                        <Select.Option value="t4">T4</Select.Option>
                        <Select.Option value="t5+">T5+</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {this.state._is_pro && (
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                      <Form.Item
                        label="Volume comparé"
                        name="_estimated_volume"
                        labelCol={{
                          xs: { span: 12 },
                          md: { span: 12 },
                        }}
                        wrapperCol={{
                          xs: { span: 12 },
                          md: { span: 2 },
                        }}
                        style={{}}
                      >
                        <InputNumber
                          min={0}
                          formatter={(value) => `${value}m³`}
                          parser={(value) => value.replace("m³", "")}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            </div>
          </div>

          {this.state._rooms.map((room, index) => {
            return (
              <InteractiveRoom
                name={room.name}
                index={index}
                key={index}
                images={room.photos}
                update_room_name={this.update_room_name}
                add_photo={(room_index, file) =>
                  this.add_photo(room_index, file)
                }
                remove_room={() => this.remove_room(index)}
              />
            )
          })}

          <div className="add-button-div">
            <Button
              className="add-button"
              onClick={() => this.add_room()}
              type="dashed"
              shape="circle"
              size="large"
            >
              {" "}
              +{" "}
            </Button>
          </div>

          <div className="add-button-div">
            <Button
              className="predict-button"
              key="submit"
              htmlType="submit"
              form="form-prediction"
              loading={this.state._isloading}
              // onClick={this.start_prediction}
            >
              Prédiction
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default Home

async function uploadFiles(rooms, housing_info) {
  let user = ""

  return create_user(housing_info)
    .then((user_id) => {
      user = user_id
      return create_rooms(user_id, rooms)
    })
    .then((room_ids) => {
      return upload_photos(rooms, room_ids)
    })
    .then(() => {
      return launch_prediction(user)
    })
    .then(fetch_job)
    .then(get_estimation)
    .catch((error) => {
      alert(error)
    })
}

async function create_user(housing_info) {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/users/`

  const body = {
    BETA_user_id: localStorage.getItem("user"),
    ...housing_info,
  }

  const replacer = (key, value) => (typeof value === "undefined" ? null : value)
  const body_json = JSON.stringify(body, replacer)

  return fetch(url, {
    method: "POST",
    body: body_json,
  })
    .then((res) => {
      return res.json()
    })
    .then((dataResult) => {
      return dataResult.user_id
    })
}

async function create_rooms(user_id, rooms) {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/users/${user_id}/rooms`

  const lines = []

  rooms.forEach((room) => {
    lines.push(`{"name": "${room.name}"}`)
  })

  const body = `[${String(lines.join(","))}]`
  return fetch(url, {
    method: "POST",
    body,
  })
    .then((res) => {
      return res.json()
    })
    .then((dataResult) => {
      return dataResult
    })
}
async function upload_photos(rooms, room_ids) {
  await Promise.all(
    rooms.map(async (room, index) => {
      const formData = new FormData()

      room.photos.forEach((photo) => {
        formData.append("photos", photo)
      })

      const url = `${process.env.REACT_APP_BASE_URL}/v1/rooms/${room_ids[index].room_id}/photos`
      return await fetch(url, {
        method: "POST",
        body: formData,
      })
    })
  )
}

async function launch_prediction(user_id) {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/users/${user_id}/prediction`
  return fetch(url, {
    method: "POST",
  })
    .then((res) => {
      return res.json()
    })
    .then((dataResult) => {
      return dataResult.job_id
    })
}

async function fetch_job(job_id) {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/jobs/${job_id}`
  return fetch(url, {
    method: "GET",
  })
    .then((res) => {
      return res.json()
    })
    .then((dataResult) => {
      if (!dataResult.estimation_id) {
        return fetch_job(job_id)
      }
      return dataResult.estimation_id
    })
}

async function get_estimation(estimation_id) {
  const url = `${process.env.REACT_APP_BASE_URL}/v1/estimations/${estimation_id}`
  return fetch(url, {
    method: "GET",
  }).then((res) => {
    return res.json()
  })
}

import { navigate } from "@reach/router"
import React, { Component } from "react"
import { Helmet } from "react-helmet"

import ResultRoom from "../components/ResultRoom"
import data from "../assets/labelmap.json"

class ResultsPage extends Component {
  constructor(props) {
    super(props)

    const options = []
    Object.entries(data).map(([key]) => {
      options.push({
        value: key,
        label: key,
      })
    })
    const datas = { options }

    this.state = {
      _rooms: [],
      _prediction: {
        total_volume: 0,
      },
      _datas: datas,
    }
  }

  componentDidMount() {
    if (!this.props.location.state) {
      setTimeout(() => navigate(""), 0)
    } else {
      const rooms = {}
      this.props.location.state._prediction.room_estimations.forEach(
        (room, index) => {
          rooms[room.room_id] = { ...this.props.location.state._rooms[index] }
          rooms[room.room_id].objects = room.objects_detected.objects
            .map((item) => item.label)
            .reduce((acc, current) => {
              if (acc[current]) {
                acc[current] += 1
              } else {
                acc[current] = 1
              }
              return acc
            }, {})
          rooms[room.room_id].volume = this.calculateVolumeForObjects(
            rooms[room.room_id].objects
          )
        }
      )
      this.setState({
        _prediction: this.props.location.state._prediction,
        _rooms: rooms,
      })
    }
  }

  calculateVolumeForObjects(objects) {
    let volume_total = 0.0
    Object.keys(objects).forEach((key) => {
      volume_total += data[key] * objects[key]
    })
    return volume_total
  }

  addObject(id, label) {
    const objects = {
      ...this.state._rooms[id].objects,
      [label]:
        this.state._rooms[id].objects[label] === undefined
          ? 1
          : this.state._rooms[id].objects[label] + 1,
    }
    const volume = this.calculateVolumeForObjects(objects)
    this.setState({
      _rooms: {
        ...this.state._rooms,
        [id]: {
          ...this.state._rooms[id],
          objects,
          volume,
        },
      },
    })
  }

  substractObject(id, label) {
    const objects = {
      ...this.state._rooms[id].objects,
      [label]:
        this.state._rooms[id].objects[label] === 0
          ? 0
          : this.state._rooms[id].objects[label] - 1,
    }
    const volume = this.calculateVolumeForObjects(objects)
    this.setState({
      _rooms: {
        ...this.state._rooms,
        [id]: {
          ...this.state._rooms[id],
          objects,
          volume,
        },
      },
    })
  }

  calculateTotalVolume() {
    let total = 0.0
    Object.keys(this.state._rooms).map((key) => {
      total += this.state._rooms[key].volume
    })
    return total
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>IA Volume</title>
        </Helmet>
        <div>
          {Object.keys(this.state._rooms).map((key) => {
            return (
              <ResultRoom
                key={key}
                {...this.state._rooms[key]}
                datas={this.state._datas}
                addObject={(label) => this.addObject(key, label)}
                substractObject={(label) => this.substractObject(key, label)}
              />
            )
          })}

          <div className="result-container-div">
            <div className="result-div">
              Volume total: {this.calculateTotalVolume().toFixed(1)} m³
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResultsPage

import React from "react"
import './App.less'
import { uuid } from "uuidv4"
import { Layout, Col, Row } from "antd"
import { Router } from "@reach/router"
import logo_myjugaad from "./img/logo_myjugaad.png"
import logo_bialx from "./img/logo-small.png"
import HomePage from "./pages/HomePage"
import ResultsPage from "./pages/ResultsPage"

const { Header } = Layout

function App() {
  const user = localStorage.getItem("user")
  if (user == null) {
    localStorage.setItem("user", uuid())
  }

  return (
    <Layout>
      <Header className="header ant-layout-header-custom">
        <Row type="flex" justify="space-between" align="middle">
          <Col xs={{ span: 8 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <img className="logo" src={logo_myjugaad} />
          </Col>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 12 }}>
            <div className="header-title">Prédiction de volume (Beta)</div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
            <Row type="flex" justify="end">
              <img className="logo" src={logo_bialx} />
            </Row>
          </Col>
        </Row>
      </Header>

      <Layout className="body-fill">
        <div
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
          }}
        >
          <Col xs={{ span: 24 }} md={{ span: 0 }} lg={{ span: 0 }}>
            <h2 style={{ textAlign: "center", font: "bold" }}>
              Prédiction de volume (Beta)
            </h2>
          </Col>
          <Router>
            <HomePage path="/" />
            <ResultsPage path="/results" />
          </Router>
        </div>
      </Layout>
    </Layout>
  )
}

export default App
